<template>
  <Login v-if="visible" @toggle="toggleVisibility" />
  <Register v-else @toggle="toggleVisibility" />
  <div style="text-align: center;">
    <Button
      class="p-button-secondary p-button-text p-mt-5"
      @click="
        router.push({
          name: 'About',
        })
      "
    >
      Help / About MyCFP</Button
    >
  </div>
</template>

<script>
import Register from "../components/Register.vue";
import Login from "../components/Login.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
// import getUser from "../composables/getUser";

export default {
  name: "Home",
  components: { Register, Login },
  setup() {
    const router = useRouter();
    // const { user, isAdmin } = getUser();
    // const checkAdmin = () => {
    //   console.log("isAdmin.value", isAdmin.value);
    //   if (isAdmin.value) {
    //     router.push({
    //       name: "Conferences",
    //     });
    //   } else if (user.value) {
    //     router.push({
    //       name: "Speaker",
    //       params: {
    //         id: user.value.uid,
    //       },
    //     });
    //   } else {
    //     return;
    //   }
    // };

    // checkAdmin();
    const visible = ref(true);

    const toggleVisibility = () => {
      visible.value = !visible.value;
    };

    return { visible, toggleVisibility, router };
  },
};
</script>
