import { ref } from "vue";
import { db } from "../firebase/config";

const GetInactiveConferences = () => {
  const loadingInactive = ref(false);
  const getInactiveDocError = ref(false);
  const inactiveConferences = ref([]);
  const useGetInactiveConferences = async () => {
    loadingInactive.value = true
    await db
      .collection("conference")
      .where("active", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log("found!");
          console.log(doc.id, " => ", doc.data());
          inactiveConferences.value.push({ uid: doc.id, ...doc.data() });
        });
        loadingInactive.value = false

      })
      .catch((error) => {
        loadingInactive.value = false

        console.log("Error getting documents: ", error);
      });
  };
  return { useGetInactiveConferences, loadingInactive, getInactiveDocError, inactiveConferences };
};

export default GetInactiveConferences;
