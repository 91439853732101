<template>
  <img
    class="p-m-5"
    src="../assets/logo-full.png"
    alt="conference logos"
    style="max-width: 250px;  margin-left: auto!important;  margin-right: auto!important;"
  />
  <div
    class="p-shadow-9 brw"
    style="max-width: 400px; margin-left: auto; margin-right: auto; margin-top: 100px; padding: 20px;"
  >
    <h1 class="p-m-1 p-text-center">Please Login</h1>

    <div class="p-mt-3 p-mb-3">
      <span class="">
        <InputText
          class="p-mb-3"
          placeholder="Email"
          id="username"
          type="text"
          v-model="email"
          style="width: 100%;"
        />
      </span>
      <span class="">
        <InputText
          class="p-mb-1"
          id="username"
          type="password"
          v-model="password"
          style="width: 100%;"
          placeholder="Password"
        />
      </span>
      <div @click="displayBasic = true" class="p-mt-1">
        <small>Forgot Password?</small>
      </div>
    </div>
    <Button
      label="Login"
      icon="pi pi-check"
      class="p-button p-m-1"
      @click="handleSubmit"
    />
    <Button
      @click="$emit('toggle')"
      label="New? Register Here"
      class="p-button-secondary p-button-text"
    />
    <p style="color: var(--pink-500);">{{ error }}</p>
  </div>

  <Dialog
    header="Reset Password"
    :modal="true"
    v-model:visible="displayBasic"
    :style="{ width: '50vw' }"
  >
    <div v-if="!resetSent">
      <label class="p-d-block p-my-2" for="emailto">Email:</label>

      <InputText
        type="text"
        v-model="emailResetTo"
        name="emailto"
        class="p-inputtext-lg"
        placeholder=""
        style="width: 100%;"
      />
    </div>
    <div v-else>
      <p>
        An email has been sent to <strong>{{ emailResetTo }}</strong
        >. If the email does not show up in your inbox within a few minutes
        check your spam folder.
      </p>
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        @click="closeBasic"
        class="p-button-text"
      />
      <Button
        label="Send"
        icon="pi pi-send"
        @click="useResetPassword"
        autofocus
      />
      <p v-if="resetError">{{ resetError }}</p>
      <!-- <button @click="test">test</button> -->
    </template>
  </Dialog>
</template>

<script>
import { projectAuth } from "../firebase/config";
import getUser from "../composables/getUser";

import useLogin from "../composables/useLogin";
import GetSpeakerConfirmation from "../composables/GetSpeakerConfirmation";
import { useRouter } from "vue-router";
import { ref } from "vue";
export default {
  emits: ["toggle"],

  setup() {
    const { getSpeaker, getSpeakerError } = GetSpeakerConfirmation();
    const { error, login, isPending, userUid } = useLogin();
    const router = useRouter();





const { user, isAdmin } = getUser();
    console.log(user.value, "user");

    const checkAdmin = () => {
      console.log("isAdmin.value", isAdmin.value);
      if (isAdmin.value) {
        router.push({
          name: "Conferences",
        });
        return;
      } else if (user.value) {
        router.push({
          name: "Speaker",
          params: {
            id: user.value.uid,
          },
        });
      } else {
        return;
      }
    };

    checkAdmin();




    const email = ref("");
    const password = ref("");

    const displayBasic = ref(false);
    const emailResetTo = ref("");
    const resetSent = ref(false);
    const closeBasic = () => {
      displayBasic.value = false;
    };
    const resetError = ref(null);

    const useResetPassword = async () => {
      await projectAuth
        .sendPasswordResetEmail(emailResetTo.value)
        .then(function() {
          // Password reset email sent.
          console.log("reset sent");
          resetSent.value = true;
        })
        .catch(function(error) {
          // Error occurred. Inspect error.code.
          resetError.value = error.message;
        });
    };

    const handleSubmit = async () => {
      await login(email.value, password.value);
      if (!error.value) {
        console.log("🔥 about to check for", userUid.value, "user doc");
        await getSpeaker(userUid.value);
        console.log("🔥🔥", getSpeakerError.value);
        if (getSpeakerError.value) {
          console.log(
            "there was an error finding the doc, send them to the start profile page"
          );
          return router.push({ name: "StartProfile" });
        }
        console.log(getSpeakerError);
        router.push({
          name: "Speaker",
          params: {
            id: userUid.value,
          },
        });
      }
    };

    return {
      resetSent,
      email,
      password,
      error,
      isPending,
      handleSubmit,
      displayBasic,
      closeBasic,
      emailResetTo,
      useResetPassword,
      resetError,
    };
  },
};
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
