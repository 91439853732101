import { ref } from "vue";
import { db } from "../firebase/config";

const GetSpeakerConfirmation = () => {
  const loadingGetSpeaker = ref(false);
  const getSpeakerError = ref(false);
  const activeConferences = ref(null);
  const speakerData = ref(null)
  const getSpeaker = async (uid) => {
    getSpeakerError.value = false;
    await db
      .collection("speakers")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Document data:", doc.data());
          speakerData.value = doc.data();
        } else {
          // doc.data() will be undefined in this case
          getSpeakerError.value = true;

          console.log("No such document!");
        }
      })
      .catch((error) => {
        getSpeakerError.value = true;
        console.log("Error getting documents: ", error);
      });
  };
  return { getSpeaker, loadingGetSpeaker, getSpeakerError, activeConferences, speakerData };
};

export default GetSpeakerConfirmation;
