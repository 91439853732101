import { ref } from "vue";
import { db } from "../firebase/config";

const EditDocument = () => {
  const newEditedDoc = ref("");
  const editDocError = ref(false);
  const useEditDocument = async (collection, document, docUid) => {
    await db
      .collection(collection)
      .doc(docUid)
      .set(document)
      .then(() => {
        console.log("Document written with ID: ");
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };
  return { useEditDocument, newEditedDoc, editDocError };
};

export default EditDocument;
