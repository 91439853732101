import { ref } from "vue";
import { db } from "../firebase/config";

const DeleteDocument = () => {
  const deleteDocError = ref(false);
  const deletedUid = ref("");

  const useDeleteDocument = async (collection, docUid) => {
    deleteDocError.value = false;
    deletedUid.value = "";
    await db
      .collection(collection)
      .doc(docUid)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        deletedUid.value = docUid;
      })
      .catch((error) => {
        deleteDocError.value = true;
        console.error("Error removing document: ", error);
      });
  };

  return { useDeleteDocument, deleteDocError, deletedUid };
};

export default DeleteDocument;
