import { ref } from "vue";
import { db } from "../firebase/config";

const GetConference = () => {
  const loadingGetConference = ref(false);
  const getConferenceError = ref(false);
  const conference = ref(null);
  const useGetConference = async (uid) => {
    loadingGetConference.value = true;
    getConferenceError.value = false;
    await db
      .collection("conference")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          conference.value = doc.data();
          loadingGetConference.value = false;
        } else {
          // doc.data() will be undefined in this case
          getConferenceError.value = true;

          console.log("No such document!");
        }
      })
      .catch((error) => {
        getConferenceError.value = true;
        console.log("Error getting documents: ", error);
      });
    loadingGetConference.value = false;
  };
  return {
    loadingGetConference,
    getConferenceError,
    conference,
    useGetConference,
  };
};

export default GetConference;
