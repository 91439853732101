import { createApp } from "vue";
import { projectAuth } from "./firebase/config";

import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Menubar from "primevue/menubar";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
// import Badge from "primevue/badge";
import Rating from "primevue/rating";
import Chart from "primevue/chart";
import Panel from "primevue/panel";
import ToastService from "primevue/toastservice";

import AutoComplete from "primevue/autocomplete";
import BadgeDirective from "primevue/badgedirective";
import "primeflex/primeflex.css";
import InputSwitch from "primevue/inputswitch";
import FileUpload from "primevue/fileupload";
import Textarea from "primevue/textarea";
import RadioButton from "primevue/radiobutton";
import Divider from "primevue/divider";
import ProgressSpinner from "primevue/progressspinner";
import Carousel from "primevue/carousel";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Menu from "primevue/menu";
import ConfirmationService from "primevue/confirmationservice";
import Checkbox from 'primevue/checkbox';

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
let app;

projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);

    app.use(router);
    app.use(PrimeVue, { ripple: true });
    app.use(ToastService);
    app.use(ConfirmationService);

    app.directive("badge", BadgeDirective);
    // app.component("Badge", Badge);
    app.component("Chart", Chart);
    app.component("Avatar", Avatar);
    app.component("AvatarGroup", AvatarGroup);
    app.component("RadioButton", RadioButton);
    app.component("ProgressSpinner", ProgressSpinner);
    app.component("Panel", Panel);
    app.component("Carousel", Carousel);
    app.component("Checkbox", Checkbox);

    app.component("Textarea", Textarea);
    app.component("FileUpload", FileUpload);
    app.component("InputSwitch", InputSwitch);
    app.component("DataTable", DataTable);
    app.component("AutoComplete", AutoComplete);
    app.component("Divider", Divider);
    app.component("Column", Column);
    app.component("Rating", Rating);
    app.component("ColumnGroup", ColumnGroup);
    app.component("Dialog", Dialog);
    app.component("Button", Button);
    app.component("Calendar", Calendar);
    app.component("Menubar", Menubar);
    app.component("Menu", Menu);

    app.component("InputText", InputText);
    app.component("Dropdown", Dropdown);
    app.mount("#app");
  }
});
