<template>
  <div v-if="speaker">
    <div
      :style="
        'background-color: #34464B; background-image: url(' +
          backgroundImage +
          '); background-size: cover;'
      "
    >
      <div
        style="max-width: 1400px; margin: auto; padding-top: 20px;
    padding-bottom: 20px;"
      >
        <h1 class="p-m-3" style="color: white;">
          Profile
        </h1>
      </div>
    </div>
    <div style="max-width: 1400px; margin: auto;">
      <div class="">
        <h2 class="p-m-3">{{ speaker.firstName }} {{ speaker.lastName }}</h2>
      </div>
      <div>
        <div class="p-grid">
          <div class="p-col-12 p-md-6">
            <div class="card p-m-3 p-p-3 p-shadow-2 brw">
              <Panel
                header="Presenter"
                style="white-space:pre-wrap;"
                class="p-mb-4"
                
                
              >
                <template #icons>
                  <Button
                    @click="editSpeaker"
                    icon="pi pi-cog"
                    class="p-button-rounded p-button-secondary p-button-text"
                  />
                </template>
                <div class="p-d-flex p-ai-center p-jc-between">
                  <div>
                    <h3>{{ speaker.firstName }} {{ speaker.lastName }}</h3>
                    <p>{{ speaker.jobTitle }}, {{ speaker.company }}</p>
                    <p>{{ speaker.certifications }}</p>
            
                  </div>
                  <div class="p-mr-2">
                    <Avatar
                      :image="speaker.imageCropUrl"
                      size="xlarge"
                      shape="circle"
                    />
                  </div></div
              ></Panel>
              <!-- <Panel
                header="Headshot"
                style="white-space:pre-wrap;"
                class="p-mb-4"
              >
                <template #icons>
                  <Button
                    @click="editSpeaker"
                    icon="pi pi-cog"
                    class="p-button-rounded p-button-secondary p-button-text"
                  />
                </template>
                <div class="p-d-flex p-ai-center p-jc-between">
                  <div class="p-mr-2">
                    <Avatar :image="speaker.imageCropUrl" size="xlarge" />
                  </div></div
              ></Panel> -->
            </div>
          </div>
          <div class="p-col-12 p-md-6">
            <div class="card p-m-3 p-p-3 p-shadow-2 brw">
              <Panel
                header="Contact"
                style="white-space:pre-wrap;"
                class="p-mb-4"
              >
                <template #icons>
                  <Button
                    @click="editSpeaker"
                    icon="pi pi-cog"
                    class="p-button-rounded p-button-secondary p-button-text"
                  />
                </template>
                <a
                  :href="'tel:' + speaker.cell"
                  class="p-d-flex p-ai-center p-my-3"
                >
                  <i class="pi pi-phone"></i> cell: {{ speaker.cell }}
                </a>
                <a
                  v-if="speaker.workPhone"
                  :href="'tel:' + speaker.workPhone"
                  class="p-d-flex p-ai-center p-my-3"
                >
                  <i class="pi pi-phone"></i> work: {{ speaker.workPhone }}
                </a>
                <a
                  :href="'mailto:' + speaker.email"
                  class="p-d-flex p-ai-center p-my-3"
                >
                  <i class="pi pi-envelope"></i> {{ speaker.email }}
                </a>
                <a
                  :href="
                    'https://www.google.com/maps/search/' +
                      speaker.address +
                      ' ' +
                      speaker.city +
                      ' ' +
                      speaker.state +
                      '/'
                  "
                  target="_blank"
                  class="p-d-flex p-ai-center p-my-3"
                >
                  <i class="pi pi-map-marker"></i> {{ speaker.address }},
                  {{ speaker.city }}, {{ speaker.state }} {{ speaker.zip }}
                </a>
              </Panel>
              <Panel
                header="Actions"
                style="white-space:pre-wrap;"
                class="p-mb-4"
              >
                <template #icons>
                  <Button
                    @click="editSpeaker"
                    icon="pi pi-cog"
                    class="p-button-rounded p-button-secondary p-button-text"
                  />
                </template>
                <div class="p-fluid">
                  <Button
                    v-if="speaker.resumeUrl"
                    label="Download Resume"
                    icon="pi pi-file"
                    iconPos="left"
                    @click="downloadFile(speaker.resumeUrl)"
                  />
                  <Button
                    class="p-my-3"
                    v-if="speaker.imageUrl"
                    label="Download Headshot"
                    icon="pi pi-camera"
                    iconPos="left"
                    @click="downloadFile(speaker.imageUrl)"
                  />
                </div>
              </Panel>
              <Panel
                header="Experience"
                style="white-space:pre-wrap;"
                class="p-mb-4"
                v-if="
                  speaker.certifications ||
                    speaker.experience ||
                    speaker.education
                "
              >
                <p v-if="speaker.certifications">
                  {{ speaker.certifications }}
                </p>
                <p v-if="speaker.experience">{{ speaker.experience }}</p>
                <p v-if="speaker.education">{{ speaker.education }}</p>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import GetSpeakerConfirmation from "../composables/GetSpeakerConfirmation";
import backgroundImage from "../assets/dark-bg.jpg";

export default {
  name: "ViewProfile",
  setup(params) {
    const router = useRouter();

    const route = useRoute();
    // console.log(route);
    // console.log(route.params);
    // console.log(params);

    const {
      getSpeaker,
      getSpeakerError,
      speakerData,
    } = GetSpeakerConfirmation();
    const speaker = ref(null);
    const fetchSpeakerInfo = async () => {
      await getSpeaker(route.params.id);
      if (getSpeakerError.value) {
        console.log(
          "there was an error finding the doc, send them to the start profile page"
        );
      } else {
        // console.log("speaker was found");
        speaker.value = speakerData.value;
      }
      console.log(getSpeakerError);
    };
    fetchSpeakerInfo();
    const goToEditProfile = () => {
      router.push({
        name: "EditProfile",
        params: {
          id: route.params.id,
        },
      });
    };
    const editSpeaker = () => {
      console.log("edit speaker...");
      router.push({
        name: "EditProfile",
        params: {
          id: route.params.id,
        },
      });
    };
    const downloadFile = (url) => {
      window.location.href = url;
    };
    return {
      backgroundImage,
      speaker,
      goToEditProfile,
      params,
      editSpeaker,
      downloadFile,
    };
  },
};
</script>

<style></style>
