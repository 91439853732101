<template>
  <!-- <div v-if="user">{{ user.uid }}</div> -->
  <div style="background-color: #F8F9FA;">
    <div
      style="max-width: 1400px; margin: auto;"
      class="p-p-3 p-d-flex p-ai-center p-jc-between"
    >
      <div class="p-d-flex p-ai-center">
        <img
          src="../assets/logo.png"
          alt=""
          style="max-height: 50px "
          class="p-mr-2"
        />
        <h3>My CFP</h3>
      </div>
      <div>
        <Button
          label="Dashboard"
          icon="pi pi-user"
          class="p-button-secondary p-button-text p-m-1"
          @click="goToDash"
        />
        <Button
          label="Logout"
          icon="pi pi-power-off"
          class="p-button-secondary p-button-text p-m-1"
          @click="logoutUserOut"
        />
      </div>
    </div>
  </div>
  <!-- <div style="background-color: #F8F9FA;" class="p-d-flex">
    <Menubar style="flex-grow: 1;" :model="items">
      <template #start>
        <img src="../assets/logo.png" alt="" style="max-height: 50px " />
      </template>
      <template #end> </template>
    </Menubar>
  </div> -->
</template>

<script>
import { ref } from "vue";
import useLogout from "../composables/useLogout";
import { useRouter } from "vue-router";
import getUser from "../composables/getUser";

export default {
  name: "Navbar",
  setup() {
    const { user } = getUser();

    const displayAddNewConference = ref(false);
    const useHideAddNewConference = () => {
      console.log("emmit init");
      displayAddNewConference.value = false;
    };
    const { error, logout } = useLogout();
    const router = useRouter();

    const handleLogout = () => {
      console.log("starting to logout...");
      logout();
      if (!error.value) {
        router.push({ name: "Home" });
      } else {
        console.log(error.value);
      }
    };
    const displayModalnext = ref(1);

    const testval = () => {
      console.log(displayAddNewConference.value);
    };
    const items = ref([
      {
        label: "Dashboard",
        icon: "pi pi-fw pi-user",
        command: () => {
          router.push({
            name: "Speaker",
            params: {
              id: user.value.uid,
            },
          });
        },
      },

      {
        label: "Logout",
        icon: "pi pi-fw pi-power-off",
        command: () => {
          console.log("starting to logout...");
          logout();
          if (!error.value) {
            router.push({ name: "Home" });
          } else {
            console.log(error.value);
          }
        },
      },
    ]);
    const logoutUserOut = () => {
      console.log("starting to logout...");
      logout();
      if (!error.value) {
        router.push({ name: "Home" });
      } else {
        console.log(error.value);
      }
    };
    const goToDash = () => {
      router.push({
        name: "Speaker",
        params: {
          id: user.value.uid,
        },
      });
    };
    return {
      goToDash,
      logoutUserOut,
      handleLogout,
      items,
      displayAddNewConference,
      useHideAddNewConference,
      testval,
      displayModalnext,
      user,
    };
  },
};
</script>

<style>
.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: none !important;
  border-radius: 0px !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  box-shadow: none !important;
}
</style>
