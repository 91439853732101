<template>
  <div
    v-show="showForm"
    :style="
      'background-color: #34464B; background-image: url(' +
        backgroundImage +
        '); background-size: cover;'
    "
  >
    <div
      style="max-width: 1400px; margin: auto;     padding-top: 20px;
    padding-bottom: 20px;"
    >
      <h1 class="p-m-3" style="color: white;">
        Start Profile
      </h1>
    </div>
  </div>
  <div style="max-width: 1400px; margin: auto;" v-show="showForm">
    <div class="p-m-3">
      <h3>
        Before submitting your presentation tell us a little about yourself.
      </h3>
      <p>
        This information can be modified later from your profile page and will
        automatically be attached to any presentations you submit.
      </p>
    </div>

    <div class="card p-m-3 p-p-3 p-shadow-2 brw">
      <div>
        <div class="">
          <Panel header="General Information">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-6">
                <label for="firstname">First Name</label>
                <InputText
                  v-model="firstName"
                  v-bind:class="{ 'p-invalid': firstNameInvalid }"
                  id="firstname"
                  type="text"
                />
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="lastname">Last Name</label>
                <InputText
                  v-model="lastName"
                  v-bind:class="{ 'p-invalid': lastNameInvalid }"
                  id="lastname"
                  type="text"
                />
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="lastname">Company</label>
                <InputText
                  v-model="company"
                  v-bind:class="{ 'p-invalid': companyInvalid }"
                  id="company"
                  type="text"
                />
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="lastname">Title</label>
                <InputText
                  v-model="jobTitle"
                  id="lastname"
                  v-bind:class="{ 'p-invalid': jobTitleInvalid }"
                  type="text"
                />
              </div>

              <div class="p-field p-col-12">
                <label for="address">Address</label>
                <Textarea
                  v-model="address"
                  id="address"
                  rows="2"
                  v-bind:class="{ 'p-invalid': addressInvalid }"
                />
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="city">City</label>
                <InputText
                  v-model="city"
                  id="city"
                  type="text"
                  v-bind:class="{ 'p-invalid': cityInvalid }"
                />
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="state">State</label>
                <InputText
                  v-model="state"
                  v-bind:class="{ 'p-invalid': stateInvalid }"
                />
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="zip">Zip</label>
                <InputText
                  v-model="zip"
                  id="zip"
                  type="text"
                  v-bind:class="{ 'p-invalid': zipInvalid }"
                />
              </div>

              <div class="p-field p-col-12 p-md-6">
                <label for="workPhone">Work Phone Number </label>
                <InputText
                  v-model="workPhone"
                  id="workPhone"
                  type="text"
                  class=""
                  v-bind:class="{ 'p-invalid': workPhoneInvalid }"
                />
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="cell"
                  >Cell Phone Number
                  <small
                    >(To be used onsite at the event only if needed)</small
                  ></label
                >
                <InputText
                  v-model="cell"
                  id="cell"
                  type="text"
                  class=""
                  v-bind:class="{ 'p-invalid': cellInvalid }"
                />
              </div>
            </div>
          </Panel>
          <Panel header="Headshot" class="p-my-5">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <label
                  for="headshot"
                  v-bind:class="{ 'p-invalid': imageFileInvalid }"
                  >Upload Current Headshot
                </label>

                <div class="upload-btn-wrapper">
                  <input
                    @change="handleCrop"
                    type="file"
                    id="fileInput"
                    class=""
                    v-bind:class="{ 'p-invalid': imageFileInvalid }"
                  />
                  <p v-if="imageFileInvalid" style="color: var(--pink-500);">
                    Select File
                  </p>
                </div>
              </div>
              <div class="p-field p-col-12" v-show="showPreview">
                <p>Crop image to headshot</p>
                <p></p>
                <div style="width: 300px; height: 300px;">
                  <canvas id="canvas">
                    Your browser does not support the HTML5 canvas element.
                  </canvas>
                </div>

                <div id="result"></div>
              </div>
            </div>
          </Panel>
          <Panel header="Experience">
            <div class="">
              <div class="p-m-1" style="margin-bottom: 20px!important;">
                <RadioButton
                  name="experience"
                  value="upload"
                  v-model="experienceMethod"
                />
                <label
                  class="p-mr-4 p-ml-2"
                  for="experience"
                  v-bind:class="{ 'p-invalid': imageFileInvalid }"
                  >Upload Resumé or CV</label
                >

                <RadioButton
                  name="experience"
                  value="enter"
                  v-model="experienceMethod"
                />
                <label class="p-mr-1 p-ml-2" for="experience"
                  >Enter Information</label
                >
              </div>

              <div v-if="experienceMethod == 'upload'" class="p-field p-col-12">
                <label for="resume">Upload CV or Resumé </label>

                <div class="upload-btn-wrapper">
                  <input
                    @change="handleResume"
                    type="file"
                    id="resume"
                    v-bind:class="{ 'p-invalid': resumeFileInvalid }"
                  />
                  <p v-if="resumeFileInvalid" style="color: var(--pink-500);">
                    Select File
                  </p>
                </div>
              </div>
              <div v-else class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                  <label for="address">Education </label>
                  <Textarea
                    v-model="education"
                    id="education"
                    rows="4"
                    v-bind:class="{ 'p-invalid': educationInvalid }"
                  />
                </div>
                <div class="p-field p-col-12">
                  <label for="certifications"
                    >Certifications/Credentials
                  </label>
                  <Textarea
                    v-model="certifications"
                    id="certifications"
                    rows="4"
                    v-bind:class="{ 'p-invalid': certificationsInvalid }"
                  />
                </div>
                <div class="p-field p-col-12">
                  <label for="education">Practice/Work Experience </label>
                  <Textarea
                    v-model="experience"
                    id="education"
                    rows="4"
                    v-bind:class="{ 'p-invalid': experienceInvalid }"
                  />
                </div>
              </div>
            </div>
          </Panel>
        </div>
        <Button
          class="p-mt-3"
          :loading="saveSpeakerProfileLoading"
          @click="updateProfile"
          label="Submit"
        />
        <!-- <Button class="p-mt-3" @click="updateProfile" label="Submit" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import getUser from "../composables/getUser";
import useStorage from "../composables/useStorage";
import saveSpeakerProfile from "../composables/saveSpeakerProfile";
import Cropper from "cropperjs";
import backgroundImage from "../assets/dark-bg.jpg";
import GetSpeakerConfirmation from "../composables/GetSpeakerConfirmation";

export default {
  name: "StartProfile",
  setup(props) {
    const { getSpeaker, getSpeakerError } = GetSpeakerConfirmation();
    const router = useRouter();
    const {
      addDocument,
      newDoc,
      addDocError,
      saveSpeakerProfileLoading,
    } = saveSpeakerProfile();
    const {
      uploadImage,
      uploadImageStandard,
      uploadResume,
      deleteImage,
      url,
      filePath,
      error,
    } = useStorage();
    const { user } = getUser();
    const firstName = ref(null);
    const lastName = ref(null);
    const company = ref(null);
    const jobTitle = ref(null);
    const address = ref(null);
    const city = ref(null);
    const state = ref(null);
    const zip = ref(null);
    const cell = ref(null);
    const workPhone = ref(null);
    console.log(deleteImage);
    console.log(props.myinfo);
    const imageFile = ref(null);
    const imageUrl = ref(null);
    const imageFilePath = ref(null);
    const imageCropUrl = ref(null);
    const imageCropFilePath = ref(null);
    const resumeFile = ref(null);
    const resumeUrl = ref(null);
    const resumeFilePath = ref(null);
    const education = ref(null);
    const certifications = ref(null);
    const experience = ref(null);
    const experienceMethod = ref("upload");
    //image references
    const croppedImage = ref(null);
    const newImage = ref(null);
    let cropperNew;

    const showForm = ref(false);
    const fetchSpeakerInfo = async () => {
      console.log("🔥 about to check for");
      await getSpeaker(user.value.uid);
      console.log("🔥🔥", getSpeakerError.value);
      if (!user.value) {
        router.push({
          name: "Home",
        });
      }
      if (getSpeakerError.value) {
        console.log("user found without profile");
        console.log(user.value.uid);
        showForm.value = true;

        // router.push({
        //   name: "Home",
        // });
      } else {
        showForm.value = false;
        router.push({
          name: "Speaker",
          params: {
            id: user.value.uid,
          },
        });
        return;
        // console.log("speaker was found without profile");
        // router.push({
        //   name: "StartProfile",
        // });
      }
      console.log(getSpeakerError);
    };
    fetchSpeakerInfo();

    const handleImage = (e) => {
      // const checkvalue = document.querySelector("#testupload");

      imageFile.value = e.target.files[0];
    };
    const showResumeName = ref(false);
    const resumeFileName = ref(null);
    const handleResume = (e) => {
      resumeFile.value = e.target.files[0];

      showResumeName.value = true;
      resumeFileName.value = resumeFile.value.name;
    };

    const updateProfile = async () => {
      checkForm();
      if (formFail.value) {
        return;
      } else {
        await saveImage();

        const profile = {
          firstName: firstName.value,
          lastName: lastName.value,
          searchName: lastName.value.toLowerCase(),
          imageUrl: imageUrl.value,
          imageFilePath: imageFilePath.value,
          imageCropUrl: imageCropUrl.value,
          imageCropFilePath: imageCropFilePath.value,
          resumeUrl:
            experienceMethod.value == "upload" ? resumeUrl.value : false,
          resumeFilePath:
            experienceMethod.value == "upload" ? resumeFilePath.value : false,
          education:
            experienceMethod.value == "enter" ? education.value : false,
          certifications:
            experienceMethod.value == "enter" ? certifications.value : false,
          experience:
            experienceMethod.value == "enter" ? experience.value : false,
          email: user.value.email,
          userUid: user.value.uid,
          company: company.value,
          jobTitle: jobTitle.value,
          address: address.value,
          city: city.value,
          state: state.value,
          zip: zip.value,
          cell: cell.value,
          workPhone: workPhone.value,
        };
        await addDocument("speakers", user.value.uid, profile);
        if (!error.value) {
          // console.log(profile)
          router.push({
            name: "Speaker",
            params: {
              id: user.value.uid,
            },
          });
        }
        console.log(newDoc, addDocError);
      }
    };
    const showPreview = ref(false);
    const handleCrop = () => {
      let canvas = document.getElementById("canvas");
      let context = document.getElementById("canvas").getContext("2d");
      // let result = document.getElementById("result");
      let fileInput = document.getElementById("fileInput");

      if (fileInput.files && fileInput.files[0]) {
        if (fileInput.files[0].type.match(/^image\//)) {
          showPreview.value = true;
          var reader = new FileReader();
          reader.onload = function(evt) {
            var img = new Image();
            img.onload = function() {
              context.canvas.height = img.height;
              context.canvas.width = img.width;
              context.drawImage(img, 0, 0);

              cropperNew = new Cropper(canvas, {
                aspectRatio: 1 / 1,
                crop(event) {
                  // console.log(event.detail.x);
                  // console.log(event.detail.y);
                  // console.log(event.detail.width);
                  // console.log(event.detail.height);
                  console.log(event.detail.rotate);
                  // console.log(event.detail.scaleX);
                  // console.log(event.detail.scaleY);
                },
              });
            };
            img.src = evt.target.result;
          };
          reader.readAsDataURL(fileInput.files[0]);
          imageSelected.value = true;
        } else {
          alert("Invalid file type! Please select an image file.");
        }
      } else {
        alert("No file(s) selected.");
      }
    };
    const imageSelected = ref(null);
    const saveImage = async () => {
      saveSpeakerProfileLoading.value = true;
      let imagez = cropperNew.getCroppedCanvas().toDataURL("image/jpeg");
      let fileInput = document.getElementById("fileInput");
      let fullphoto = fileInput.files[0];

      await uploadImage(imagez, "headshot-cropped");
      console.log("CROPPED: ");
      console.log(url.value);
      console.log(filePath.value);
      imageCropUrl.value = url.value;
      imageCropFilePath.value = filePath.value;

      await uploadImageStandard(fullphoto, "headshot-full");
      imageUrl.value = url.value;
      imageFilePath.value = filePath.value;
      if (resumeFileInvalid.value) {
        resumeUrl.value = false;
        resumeFilePath.value = false;
      } else {
        await uploadResume(resumeFile.value, resumeFileName.value);
        resumeUrl.value = url.value;
        resumeFilePath.value = filePath.value;
      }
    };

    const firstNameInvalid = ref(false);
    const lastNameInvalid = ref(false);
    const companyInvalid = ref(false);
    const jobTitleInvalid = ref(false);
    const addressInvalid = ref(false);
    const cityInvalid = ref(false);
    const stateInvalid = ref(false);
    const zipInvalid = ref(false);
    const cellInvalid = ref(false);
    const workPhoneInvalid = ref(false);
    const imageFileInvalid = ref(false);
    const resumeFileInvalid = ref(false);
    const educationInvalid = ref(false);
    const certificationsInvalid = ref(false);
    const experienceInvalid = ref(false);

    const formFail = ref(false);

    const checkForm = () => {
      console.log("checking fom");
      console.log(firstName.value == null);
      console.log(firstName.value);
      console.log(firstNameInvalid.value);
      if (firstName.value == null) {
        firstNameInvalid.value = true;
      } else {
        firstNameInvalid.value = false;
      }
      if (lastName.value == null) {
        lastNameInvalid.value = true;
      } else {
        lastNameInvalid.value = false;
      }
      if (company.value == null) {
        companyInvalid.value = true;
      } else {
        companyInvalid.value = false;
      }
      if (jobTitle.value == null) {
        jobTitleInvalid.value = true;
      } else {
        jobTitleInvalid.value = false;
      }
      if (address.value == null) {
        addressInvalid.value = true;
      } else {
        addressInvalid.value = false;
      }
      if (city.value == null) {
        cityInvalid.value = true;
      } else {
        cityInvalid.value = false;
      }
      if (state.value == null) {
        stateInvalid.value = true;
      } else {
        stateInvalid.value = false;
      }
      if (zip.value == null) {
        zipInvalid.value = true;
      } else {
        zipInvalid.value = false;
      }
      if (cell.value == null) {
        cellInvalid.value = true;
      } else {
        cellInvalid.value = false;
      }
      if (workPhone.value == null) {
        workPhoneInvalid.value = true;
      } else {
        workPhoneInvalid.value = false;
      }
      if (imageSelected.value == null) {
        imageFileInvalid.value = true;
      } else {
        imageFileInvalid.value = false;
      }
      if (resumeFileName.value == null) {
        resumeFileInvalid.value = true;
      } else {
        resumeFileInvalid.value = false;
      }
      if (education.value == null) {
        educationInvalid.value = true;
      } else {
        educationInvalid.value = false;
      }
      if (certifications.value == null) {
        certificationsInvalid.value = true;
      } else {
        certificationsInvalid.value = false;
      }
      if (experience.value == null) {
        experienceInvalid.value = true;
      } else {
        experienceInvalid.value = false;
      }
      if (
        firstNameInvalid.value == true ||
        lastNameInvalid.value == true ||
        companyInvalid.value == true ||
        jobTitleInvalid.value == true ||
        addressInvalid.value == true ||
        cityInvalid.value == true ||
        stateInvalid.value == true ||
        zipInvalid.value == true ||
        cellInvalid.value == true ||
        workPhoneInvalid.value == true ||
        imageFileInvalid.value == true ||
        (experienceMethod.value == "upload" &&
          resumeFileInvalid.value == true) ||
        (experienceMethod.value == "enter" &&
          (certificationsInvalid.value == true ||
            experienceInvalid.value == true ||
            educationInvalid.value == true))
      ) {
        formFail.value = true;
      } else {
        formFail.value = false;
      }
      console.log("the form fail value is...", formFail.value);
    };

    return {
      checkForm,
      formFail,
      firstNameInvalid,
      lastNameInvalid,
      companyInvalid,
      jobTitleInvalid,
      addressInvalid,
      cityInvalid,
      stateInvalid,
      zipInvalid,
      workPhone,
      workPhoneInvalid,
      cellInvalid,
      resumeFileInvalid,
      imageFileInvalid,
      educationInvalid,
      certificationsInvalid,
      experienceInvalid,
      experienceMethod,
      resumeFileName,
      showPreview,
      showResumeName,
      backgroundImage,
      updateProfile,
      error,
      showForm,
      firstName,
      lastName,
      company,
      jobTitle,
      address,
      city,
      state,
      zip,
      cell,
      imageFile,
      handleImage,
      handleResume,
      url,
      filePath,
      education,
      certifications,
      experience,
      saveImage,
      saveSpeakerProfileLoading,
      croppedImage,

      newImage,

      handleCrop,
    };
  },
};
</script>
<style>
input[type="file"]::file-selector-button {
  border: 3px solid #e6e9eb;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #e6e9eb;
}

input[type="file"]::file-selector-button:hover {
  background-color: #888888;
  border: 3px solid #888888;
}
</style>
