// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

var firebaseConfig = {
  apiKey: "AIzaSyBYbQtHFwdZdVzkVOcI50UO3aIEYFklhwg",
  authDomain: "mycfp-4b004.firebaseapp.com",
  projectId: "mycfp-4b004",
  storageBucket: "mycfp-4b004.appspot.com",
  messagingSenderId: "626509410362",
  appId: "1:626509410362:web:1de7f22869449eca348503",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//init services
const db = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();
const functions = firebase.functions();

//timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

//export
export { projectAuth, db, projectStorage, timestamp, functions };
