<template>
  <!-- <div v-if="user">{{ user.uid }}</div> -->
  <div style="background-color: #f1f1f1;" class="p-d-flex">
    <Menubar style="flex-grow: 1;" :model="items">
      <template #start>
        <img
          src="../assets/logo.png"
          alt=""
          style="max-height: 50px "
          class="p-ml-3 p-mr-3"
        />
      </template>
      <template #end> </template>
    </Menubar>
  </div>
  <AddConferenceModal
    :showModal="displayModalnext"
    :user="user"
    @modalVisibility="useHideAddNewConference"
  />
</template>

<script>
import { ref } from "vue";
import AddConferenceModal from "../components/AddConferenceModal.vue";
import useLogout from "../composables/useLogout";
import { useRouter } from "vue-router";
import getUser from "../composables/getUser";

export default {
  components: { AddConferenceModal },

  name: "AdminNav",
  setup() {
    const { user } = getUser();

    const displayAddNewConference = ref(false);
    const useHideAddNewConference = () => {
      console.log("emmit init");
      displayAddNewConference.value = false;
    };
    const { error, logout } = useLogout();
    const router = useRouter();

    const handleLogout = () => {
      console.log("starting to logout...");
      logout();
      if (!error.value) {
        router.push({ name: "Home" });
      } else {
        console.log(error.value);
      }
    };
    const displayModalnext = ref(1);

    const testval = () => {
      console.log(displayAddNewConference.value);
    };
    const items = ref([
      {
        label: "Home",
        icon: "pi pi-fw pi-home",
        to: "/",
      },

      {
        label: "Add Conference",
        icon: "pi pi-fw pi-calendar-plus",
        command: () => {
          console.log("hi");
          displayModalnext.value += 1;
        },
      },
      {
        label: "Conferences",
        icon: "pi pi-fw pi-calendar",
        to: "/conferences",
      },
      {
        label: "Speakers",
        icon: "pi pi-fw pi-users",
        to: "/speakers",
      },

      {
        label: "Logout",
        icon: "pi pi-fw pi-power-off",
        command: () => {
          console.log("starting to logout...");
          logout();
          if (!error.value) {
            router.push({ name: "Home" });
          } else {
            console.log(error.value);
          }
        },
      },
    ]);

    return {
      handleLogout,
      items,
      displayAddNewConference,
      useHideAddNewConference,
      testval,
      displayModalnext,
      user,
    };
  },
};
</script>

<style>
.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: none !important;
  border-radius: 0px !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  box-shadow: none !important;
}
</style>
