<template>
  <img
    class="p-m-5"
    src="../assets/logo-full.png"
    alt="conference logos"
    style="max-width: 250px;  margin-left: auto!important;  margin-right: auto!important;"
  />
  <div
    class="p-shadow-9 brw"
    style="max-width: 400px; margin-left: auto; margin-right: auto; margin-top: 100px; padding: 20px;"
  >
    <h1 class="p-m-1 p-text-center">Please Register</h1>

    <div class="p-mt-3 p-mb-3">
      <span class="">
        <InputText
          class="p-mb-3"
          placeholder="Email"
          id="username"
          type="text"
          v-model="email"
          style="width: 100%;"
        />
      </span>
      <span class="">
        <InputText
          class="p-mb-1"
          id="username"
          type="password"
          v-model="password"
          style="width: 100%;"
          placeholder="Password"
        />
      </span>
    </div>
    <Button
      label="Register"
      icon="pi pi-check"
      class="p-button p-m-1"
      @click="handleSubmit"
    />
    <Button
      @click="$emit('toggle')"
      label="Login Here"
      class="p-button-secondary p-button-text"
    />
    <p style="color: var(--pink-500);">{{ error }}</p>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import useSignup from "../composables/useSignup";
export default {
  emits: ["toggle"],

  setup() {
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const { error, signup, isPending } = useSignup();

    const handleSubmit = async () => {
      await signup(email.value, password.value);
      if (!error.value) {
        router.push({ name: "StartProfile" });
      }
      console.log("error from reg: ", error);
    };

    return { email, password, error, isPending, handleSubmit };
  },
};
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
