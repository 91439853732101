import { ref } from "vue";
import { db } from "../firebase/config";

const GetCfp = () => {
  const loadingGetSpeaker = ref(false);
  const getCfpError = ref(false);
  const cfp = ref(null);
  const useGetCfp = async (uid) => {
    getCfpError.value = false;
    await db
      .collection("cfps")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          cfp.value = doc.data();
        } else {
          // doc.data() will be undefined in this case
          getCfpError.value = true;

          console.log("No such document!");
        }
      })
      .catch((error) => {
        getCfpError.value = true;
        console.log("Error getting documents: ", error);
      });
  };
  return { useGetCfp, loadingGetSpeaker, getCfpError, cfp };
};

export default GetCfp;
