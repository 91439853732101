<template>
  <div>
    <div class="p-col-12">
      <h1>Add Conference</h1>
    </div>
    <div
      class="p-m-4 p-p-4 p-shadow-2
"
    >
      <div class="p-d-flex">
        <div class="p-col-6">
          <label class="p-d-flex p-mb-2" for="range">Conference Name</label>
          <Dropdown
            class="p-inputtext-lg"
            v-model="selectedConference"
            :options="conferences"
            style="width: 100%;"
            optionLabel="name"
            optionValue="code"
            placeholder="Select a Conference"
          />
        </div>
        <div class="p-col-6">
          <label class="p-d-flex p-mb-2" for="range">Date</label>
          <Calendar
            id="range"
            class="p-d-flex p-inputtext-lg"
            style="width: 100%;"
            v-model="dateRange"
            placeholder="Select Days"
            selectionMode="range"
            :manualInput="false"
          />
        </div>
      </div>
      <div class=" p-d-md-flex">
        <div class="p-col-12 p-sm-12 p-md-4">
          <label class="p-d-flex p-mb-2" for="range">Hotel</label>

          <InputText
            type="text"
            class="p-inputtext-lg"
            placeholder="Hotel Name"
            style="width: 100%;"
          />
        </div>

        <div class="p-col-12 p-sm-12 p-md-4">
           
        </div>
        <div class="p-col-12 p-sm-12 p-md-4">
             
        </div>
      </div>
      <Button
        label="Submit"
        icon="pi pi-check"
        class="p-button-lg p-m-2"
        @click="test"
      />
    </div>
  </div>
  <AddConferenceModal />
</template>

<script>
import { ref } from "vue";
import SaveDocument from "../composables/SaveDocument";
import AddConferenceModal from "../components/AddConferenceModal.vue";
export default {
  name: "AddConference",
  components: { AddConferenceModal },

  setup() {
    const { addDocument, newDoc } = SaveDocument();

    const test = async () => {
      await addDocument("conference", { name: "MD Expo" });
      console.log(newDoc);
    };
    const dateRange = ref(null);
    const conferences = [
      { name: "MD Expo", code: "MD Expo" },
      { name: "ICE", code: "ICE" },
      { name: "OR Today event", code: "OR Today event" },
      { name: "HTM Mixer", code: "HTM Mixer" },
    ];
    const selectedConference = ref(null);
    return {
      dateRange,
      selectedConference,
      conferences,
      test,
    };
  },
};
</script>

<style></style>
