<template>
  <Dialog
    header="Send Message"
    :modal="true"
    v-model:visible="displayBasic"
    :style="{ width: '50vw' }"
  >
    <label class="p-d-block p-my-2" for="emailto">To:</label>

    <InputText
      type="text"
      v-model="emailTo"
      name="emailto"
      class="p-inputtext-lg"
      placeholder=""
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2" for="emailfrom">From:</label>

    <InputText
      type="text"
      v-model="emailFrom"
      name="emailfrom"
      class="p-inputtext-lg"
      placeholder=""
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2" for="emailsubject">Subject:</label>

    <InputText
      type="text"
      name="emailsubject"
      v-model="emailSubject"
      class="p-inputtext-lg"
      placeholder=""
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2" for="emailmessage">Message:</label>

    <Textarea
      v-model="emailMessage"
      name="emailmessage"
      rows="5"
      style="width: 100%;"
    />

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        @click="closeBasic"
        class="p-button-text"
      />
      <Button
        label="Send"
        icon="pi pi-send"
        @click="useSendMessage"
        autofocus
      />
      <p v-if="sendError">{{ sendError }}</p>
      <!-- <button @click="test">test</button> -->
    </template>
  </Dialog>
</template>

<script>
import { ref, watch } from "vue";
import SaveDocument from "../composables/SaveDocument";
import getUser from "../composables/getUser";

export default {
  name: "SendMessage",
  props: ["showModal", "speaker"],
  emits: ["modalVisibility"],
  setup(props, { emit }) {
    const { addDocument, newDoc, addDocError } = SaveDocument();
    const sendError = ref(null);
    const { user, isAdmin } = getUser();
    const emailTo = ref("");
    const emailFrom = ref("");
    const emailSubject = ref("");
    const emailMessage = ref("");

    const displayBasic = ref(false);

    const openBasic = () => {
      displayBasic.value = true;
    };
    const closeBasic = () => {
      console.log("start emit...");
      emit("modalVisibility", { status: false });

      displayBasic.value = false;
    };

    watch(
      () => props.showModal,
      (first, second) => {
        console.log(
          "Watch props.showModal function called with args:",
          first,
          second
        );
        emailTo.value = props.speaker.email;
        emailFrom.value = user.value.email;
        displayBasic.value = true;
      }
    );

    const test = () => {
      console.log(props.speaker);
      console.log(user.value);
    };

    const useSendMessage = async () => {
      let message = {
        from: "myCFP <mycfp@mdpublishing.com>",
        replyTo: `${emailFrom.value}`,
        // bcc: `${emailFrom.value}`,
        to: [`${emailTo.value}`, `${emailFrom.value}`],

        message: {
          subject: `${emailSubject.value}`,
          html: `
            <p>${emailMessage.value}</p>
            `,
          text: `${emailMessage.value}`,
        },
      };
      await addDocument("mail", message);
      if (!addDocError.value) {
        displayBasic.value = false;
      } else {
        sendError.value = addDocError.value;
      }
    };

    return {
      useSendMessage,
      sendError,
      displayBasic,
      addDocument,
      openBasic,
      closeBasic,
      newDoc,
      addDocError,
      props,
      emit,
      ref,
      watch,
      emailTo,
      emailFrom,
      emailSubject,
      emailMessage,
      test,
      user,
      isAdmin,
    };
  },
};
</script>

<style></style>
