<template>
  <div
    :style="
      'background-color: #34464B; background-image: url(' +
        backgroundImage +
        '); background-size: cover;'
    "
  >
    <div
      style="max-width: 1400px; margin: auto;     padding-top: 20px;
    padding-bottom: 20px;"
    >
      <h1 class="p-m-3" style="color: white;">
        Dashboard
      </h1>
    </div>
  </div>
  <div style="max-width: 1400px; margin: auto;">
    <div v-if="speakerProps" class="p-m-3 p-d-flex p-ai-center p-jc-between">
      <div class="p-d-flex p-ai-center">
        <Avatar
          :image="speakerProps.imageCropUrl"
          size="xlarge"
          shape="circle"
          class="p-mr-3"
        />
        <h1>{{ speakerProps.firstName }} {{ speakerProps.lastName }}</h1>
      </div>
      <div>
        <div class="">
          <Button
            v-if="isAdmin"
            label="Message"
            @click="useSendMessage"
            icon="pi pi-envelope"
            class="p-button p-button-outlined p-m-1"
          />
          <Button
            @click="goToProfile"
            label="Profile"
            icon="pi pi-user"
            class="p-button p-button-outlined p-m-1"
          />
          <Button
            @click="displayModalnext += 1"
            label="Submit Presentation"
            class="p-button p-m-1"
            icon="pi pi-plus-circle"
          />
        </div>
      </div>
    </div>
    <div v-if="loadingCfps" style="text-align: center;">
      <ProgressSpinner />
    </div>
    <div v-else>
      <AddCfpModal
        :showModal="displayModalnext"
        :user="speakerProps"
        @modalVisibility="useHideAddNewConference"
        @pushCfp="usePushCfp"
      />
      <SendMessage
        :showModal="displaySendMessageModalnext"
        :speaker="speakerProps"
        @modalVisibility="useHideSendMessage"
        @pushCfp="usePushEmail"
      />
      <EditCfpModal
        :showModal="displayEditModalnext"
        :user="presentationToEdit"
        :presentation="presentationToEdit"
        @modalVisibility="useHideAddNewConference"
        @pushCfpEditSpeaker="usePushCfpEdit"
      />

      <div v-if="matchedCfps.length" class="card p-m-3 p-p-3 p-shadow-2 brw">
        <DataTable
          :value="matchedCfps"
          stripedRows
          responsiveLayout="scroll"
          breakpoint="810px"
        >
          <template #header>
            Submitted Presentations
          </template>
          <Column field="title" header="Title" :sortable="true">
            <template #body="slotProps">
              <p
                class="alphabet-button"
                @click="
                  router.push({
                    name: 'ViewCfp',
                    params: { id: slotProps.data.uid },
                  })
                "
              >
                {{ slotProps.data.title }}
              </p></template
            >
          </Column>
          <Column field="conference" header="Conference" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.conference }},
              {{ slotProps.data.conferenceCity }}
            </template>
          </Column>
          <Column :sortable="true" field="year" header="Year"> </Column>

          <Column field="status" header="Status" :sortable="true">
            <template #body="slotProps">
              <span :class="'cfp-status ' + ` ${slotProps.data.status}-cfp`">{{
                slotProps.data.status
              }}</span>
            </template>
          </Column>
          <Column header="Actions">
            <template #body="slotProps">
              <Button
                class="p-button-outlined p-button-secondary"
                :id="slotProps.data.uid"
                type="button"
                icon="pi pi-bars"
                @click="
                  toggle($event);
                  currentUid(slotProps.data.uid);
                "
                aria-haspopup="true"
                aria-controls="overlay_menu"
              />
              <Menu
                :id="slotProps.data.uid + ' overlay_menu'"
                ref="menu"
                :data-cfpUid="slotProps.data.uid"
                :model="items"
                :popup="true"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else class="card p-m-3 p-p-3 p-shadow-2">
        <h2 class="p-text-center">No Submissions Found.</h2>
      </div>
    </div>
  </div>
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import EditDocument from "../composables/EditDocument";
import DeleteDocument from "../composables/DeleteDocument";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import backgroundImage from "../assets/dark-bg.jpg";

import GetSpeakerConfirmation from "../composables/GetSpeakerConfirmation";
import GetCfps from "../composables/GetCfps";
import getUser from "../composables/getUser";
import AddCfpModal from "../components/AddCfpModal.vue";
import SendMessage from "../components/SendMessage.vue";
import EditCfpModal from "../components/EditCfpModal.vue";
import { ref } from "vue";
export default {
  components: { AddCfpModal, EditCfpModal, ConfirmDialog, SendMessage },

  name: "Speaker",
  setup(params) {
    const { useEditDocument, newEditedDoc, editDocError } = EditDocument();
    const { useDeleteDocument, deleteDocError, deletedUid } = DeleteDocument();
    const { user, isAdmin } = getUser();
    const router = useRouter();
    const confirm = useConfirm();

    const route = useRoute();
    console.log("###QQQQQQQ###", params);
    console.log("###QQQQQQQ###", route.params.id);
    // const { useGetCfps, loading, getDocError, matchedCfps } = getCfps();
    const { useGetCfps, matchedCfps, loadingCfps } = GetCfps();
    const loadCfps = async () => {
      await useGetCfps("userUid", route.params.id);
    };
    loadCfps();
    const presentationToEdit = ref(null);
    const editCfp = (confuidevent) => {
      console.log(
        confuidevent,
        "=>",
        matchedCfps.value.find((element) => element.uid == confuidevent)
      );
      presentationToEdit.value = matchedCfps.value.find(
        (element) => element.uid == confuidevent
      );
      displayEditModalnext.value += 1;
    };

    const {
      getSpeaker,
      getSpeakerError,
      speakerData,
    } = GetSpeakerConfirmation();
    const speakerProps = ref(null);
    const testRef = ref("test");
    const fetchSpeakerInfo = async () => {
      console.log("🔥 about to check for");
      await getSpeaker(route.params.id);
      console.log("🔥🔥", getSpeakerError.value);
      if (getSpeakerError.value) {
        console.log(
          "there was an error finding the doc, send  to the start profile page"
        );
        router.push({
          name: "StartProfile",
        });
      } else {
        console.log("speaker was found");
        speakerProps.value = {
          ...speakerData.value,
          speakerUid: route.params.id,
        };
      }
      console.log(getSpeakerError);
    };
    fetchSpeakerInfo();

    if (user.value === null) {
      console.log("no user found, send home");

      router.push({
        name: "Home",
      });
    } else {
      console.log("current speaker is: ", user.value.uid);
    }
    const displayModalnext = ref(1);
    const displayEditModalnext = ref(1);
    const displaySendMessageModalnext = ref(1);

    const goToProfile = () => {
      router.push({
        name: "ViewProfile",
        params: {
          id: route.params.id,
        },
      });
    };
    const usePushCfp = (submission) => {
      console.log("testing...", submission);
      matchedCfps.value.push(submission);
    };
    const usePushCfpEdit = (submission) => {
      console.log("🦄");
      console.log("from parent", submission);
      matchedCfps.value.find((element, index) => {
        if (element.uid == activeUid.value) {
          matchedCfps.value[index] = submission;
          return true;
        }
      });
    };
    const activeUid = ref("");
    const currentUid = (uidValue) => {
      activeUid.value = uidValue;
    };
    const items = isAdmin.value
      ? ref([
          {
            label: "Status",
            items: [
              {
                label: "Approved",
                icon: "pi pi-thumbs-up",

                command: () => {
                  changeStatus(activeUid.value, "accepted");
                },
              },
              {
                label: "Pending",
                icon: "pi pi-sort",

                command: () => {
                  changeStatus(activeUid.value, "pending");
                },
              },
              {
                label: "Declined",
                icon: "pi pi-thumbs-down",

                command: () => {
                  changeStatus(activeUid.value, "declined");
                },
              },
            ],
          },
          {
            label: "Actions",
            items: [
              {
                label: "View",
                icon: "pi pi-eye",

                command: () => {
                  router.push({
                    name: "ViewCfp",
                    params: { id: activeUid.value },
                  });
                },
              },
              {
                label: "Edit",
                icon: "pi pi-pencil",

                command: () => {
                  editCfp(activeUid.value);
                },
              },
              {
                label: "Delete",
                icon: "pi pi-trash",

                command: () => {
                  deleteCfp(activeUid.value);
                },
              },
            ],
          },
        ])
      : ref([
          {
            label: "Actions",
            items: [
              {
                label: "View",
                icon: "pi pi-eye",

                command: () => {
                  router.push({
                    name: "ViewCfp",
                    params: { id: activeUid.value },
                  });
                },
              },
              {
                label: "Edit",
                icon: "pi pi-pencil",

                command: () => {
                  editCfp(activeUid.value);
                },
              },
              {
                label: "Delete",
                icon: "pi pi-trash",

                command: () => {
                  deleteCfp(activeUid.value);
                },
              },
            ],
          },
        ]);
    const menu = ref();

    const toggle = (event) => {
      console.log("hi");
      menu.value.toggle(event);
    };

    const changeStatus = (confuidevent, newStatus) => {
      console.log(
        confuidevent,
        "=>",
        matchedCfps.value.find((element) => element.uid == confuidevent)
      );
      presentationToEdit.value = matchedCfps.value.find((element, index) => {
        if (element.uid == confuidevent) {
          matchedCfps.value[index].status = newStatus;
          console.log("cfps", matchedCfps.value[index], confuidevent);
          useEditDocument("cfps", matchedCfps.value[index], confuidevent);
          return true;
        }
      });
      // displayEditModalnext.value += 1;
    };

    const deleteCfp = (uid) => {
      console.log(uid);
      confirm.require({
        message: "Are you sure you want to delete this presentation?",
        header: "Confirmation",
        acceptClass: "p-button-danger",
        rejectClass: "p-button-secondary",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          //callback to execute when user confirms the action
          await useDeleteDocument("cfps", uid);
          matchedCfps.value = matchedCfps.value.filter(
            (item) => item.uid != uid
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    };

    const useSendMessage = () => {
      console.log("starting message modal display");
      displaySendMessageModalnext.value += 1;
    };

    return {
      isAdmin,
      testRef,
      useSendMessage,
      displaySendMessageModalnext,
      deleteCfp,
      useDeleteDocument,
      deleteDocError,
      deletedUid,
      menu,
      currentUid,
      items,
      toggle,
      newEditedDoc,
      editDocError,
      presentationToEdit,
      editCfp,
      displayModalnext,
      displayEditModalnext,
      router,
      user,
      backgroundImage,
      speakerProps,
      matchedCfps,
      goToProfile,
      loadingCfps,
      usePushCfp,
      usePushCfpEdit,
      SendMessage,
    };
  },
};
</script>

<style>
.cfp-status {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  text-align: center;
}
.cfp-status.pending-cfp {
  background: #feedaf;
  color: #8a5340;
}
.cfp-status.declined-cfp {
  background: #feafaf;
  color: #8a4040;
}
.cfp-status.accepted-cfp {
  background: #affeb2;
  color: #408a52;
}

.alphabet-button:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #2196f3;
}
</style>
