import { ref } from "vue";
import { db } from "../firebase/config";

const SpeakerSearch = () => {
  const alphaList = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "zz",
  ];
  const loadingSearch = ref(false);
  const getSearchError = ref(false);
  const speakersFromSearch = ref([]);
  const useSpeakerSearch = async (query) => {
    speakersFromSearch.value = [];
    const lastLetter = query[query.split("").length - 1];
    const newLastLetter = alphaList.findIndex((letter) => letter == lastLetter);
    let secondSearchValue = query.split("");
    secondSearchValue[query.split("").length - 1] =
      alphaList[newLastLetter + 1];
    console.log(secondSearchValue);
    console.log(query, secondSearchValue.join(""));
    await db
      .collection("speakers")
      .where("searchName", ">=", query)
      .where("searchName", "<", secondSearchValue.join(""))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          speakersFromSearch.value.push({ uid: doc.id, ...doc.data() });
        });
      });
  };
  return {
    useSpeakerSearch,
    loadingSearch,
    getSearchError,
    speakersFromSearch,
  };
};

export default SpeakerSearch;
