import { ref } from "vue";
import { projectAuth } from "../firebase/config";

// refs
const user = ref(projectAuth.currentUser);
const isAdmin = ref(false);

// auth changes
projectAuth.onAuthStateChanged((_user) => {
  if (_user) {
    _user.getIdTokenResult().then((idTokenResult) => {
      idTokenResult.claims.admin
        ? (isAdmin.value = true)
        : (isAdmin.value = false);
    });
  }
  // console.log('User state change. Current user is:', _user)
  user.value = _user;
});

const getUser = () => {
  return { user, isAdmin };
};

export default getUser;
