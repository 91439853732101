import { ref } from "vue";
import { db } from "../firebase/config";

const GetActiveConferences = () => {
  const loading = ref(false);
  const getDocError = ref(false);
  const activeConferences = ref([]);
  const getConferences = async () => {
    loading.value = true
    await db
      .collection("conference")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log("found!");
          // console.log(doc.id, " => ", doc.data());
          activeConferences.value.push({ uid: doc.id, ...doc.data() });
        });
        loading.value = false

      })
      .catch((error) => {
        loading.value = false

        console.log("Error getting documents: ", error);
      });
  };
  return { getConferences, loading, getDocError, activeConferences };
};

export default GetActiveConferences;
