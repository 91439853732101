import { ref } from "vue";
import { db } from "../firebase/config";

const saveSpeakerProfile = () => {
  const newDoc = ref("");
  const addDocError = ref(false);
  const saveSpeakerProfileLoading = ref(false);
  const addDocument = async (collection, docId, document) => {
    saveSpeakerProfileLoading.value = true;
    await // Add a new document in collection "cities"
    db
      .collection(collection)
      .doc(docId)
      .set(document)
      .then(() => {
        console.log("Document successfully written!");
        saveSpeakerProfileLoading.value = false;
        addDocError.value = false;
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        saveSpeakerProfileLoading.value = false;
        addDocError.value = true;
      });
  };
  return { addDocument, newDoc, addDocError, saveSpeakerProfileLoading };
};

export default saveSpeakerProfile;
