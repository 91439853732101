<template>
<div
    :style="
      'background-color: #34464B; background-image: url(' +
        backgroundImage +
        '); background-size: cover;'
    "
  >    <div
      style="max-width: 1400px; margin: auto;     padding-top: 20px;
    padding-bottom: 20px;"
    >
      <h1 class="p-m-3" style="color: white;">
        Speakers
      </h1>
    </div>
  </div>
  <div style="max-width: 1400px; margin: auto; margin-top: 35px;">
    <div class="card p-m-3 p-p-3 p-shadow-2 brw">
      <div>
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-4">
            <div class="p-inputgroup">
              <InputText placeholder="Last Name" v-model="searchBox" />

              <Button label="Search" @click="findSpeakers" />
            </div>
          </div>
        </div>

        <Carousel
          :numVisible="27"
          :value="alphabet"
          :responsiveOptions="responsiveOptions"
        >
          <template #item="slotProps">
            <p
              class="alphabet-button"
              @click="fetchSpeakers(slotProps.data.value.toLowerCase())"
            >
              {{ slotProps.data.value }}
            </p>
          </template>
        </Carousel>
        <div v-if="speakers.length">
          <ul v-for="(speaker, index) in speakers" :key="index">
            <li
              class="alphabet-button"
              @click="
                router.push({
                  name: 'Speaker',
                  params: {
                    id: speaker.uid,
                  },
                })
              "
            >
              {{ speaker.lastName }},
              {{ speaker.firstName }}
            </li>
          </ul>
        </div>
        <div v-else class="p-text-center p-m-4">No speakers found...</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from "vue";
import GetAllSpeakers from "../composables/GetAllSpeakers";
import SpeakerSearch from "../composables/SpeakerSearch";
import getUser from "../composables/getUser";
import backgroundImage from "../assets/dark-bg.jpg";

export default {
  name: "Speakers",
  setup() {
    const router = useRouter();
    const { isAdmin } = getUser();

    const checkIsAdmin = () => {
      if (isAdmin.value) {
        console.log(isAdmin);
        console.log("admin shown");
        return;
      } else {
        console.log("no admin");
        router.push({
          name: "Home",
        });
      }
    };
    checkIsAdmin();

    const searchBox = ref("");
    const {
      useSpeakerSearch,
      loadingSearch,
      getSearchError,
      speakersFromSearch,
    } = SpeakerSearch();
    const { getConferences, speakers } = GetAllSpeakers();
    getConferences();
    const findSpeakers = async () => {
      await useSpeakerSearch(searchBox.value.toLowerCase());
      speakers.value = speakersFromSearch.value;
    };
    const alphabet = [
      { value: "A" },
      { value: "B" },
      { value: "C" },
      { value: "D" },
      { value: "E" },
      { value: "F" },
      { value: "G" },
      { value: "H" },
      { value: "I" },
      { value: "J" },
      { value: "K" },
      { value: "L" },
      { value: "M" },
      { value: "N" },
      { value: "O" },
      { value: "P" },
      { value: "Q" },
      { value: "R" },
      { value: "S" },
      { value: "T" },
      { value: "U" },
      { value: "V" },
      { value: "W" },
      { value: "X" },
      { value: "Y" },
      { value: "Z" },
    ];
    const responsiveOptions = ref([
      {
        breakpoint: "1024px",
        numVisible: 14,
        numScroll: 14,
      },
      {
        breakpoint: "600px",
        numVisible: 9,
        numScroll: 9,
      },
      {
        breakpoint: "480px",
        numVisible: 7,
        numScroll: 7,
      },
    ]);

    const fetchSpeakers = async (val) => {
      await useSpeakerSearch(val);
      speakers.value = speakersFromSearch.value;
      console.log("check", val);
    };
    return {
      speakers,
      router,
      alphabet,
      responsiveOptions,
      fetchSpeakers,
      useSpeakerSearch,
      loadingSearch,
      getSearchError,
      backgroundImage,
      speakersFromSearch,
      searchBox,
      findSpeakers,
    };
  },
};
</script>

<style>
.alphabet-button:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #2196f3;
}
</style>
