<template>
  <div v-if="isAdmin">
    <div
      :style="
        'background-color: #34464B; background-image: url(' +
          backgroundImage +
          '); background-size: cover;'
      "
    >
      <div
        style="max-width: 1400px; margin: auto;     padding-top: 20px;
    padding-bottom: 20px;"
      >
        <h1 class="p-m-3" style="color: white;">
          Conferences
        </h1>
      </div>
    </div>
    <div style="max-width: 1400px; margin: auto; margin-top: 35px;">
      <div v-if="loading" style="text-align: center;"><ProgressSpinner /></div>
      <div v-else>
        <div
          v-if="activeConferences.length"
          class="card p-m-3 p-mt-5 p-shadow-2 brw
"
        >
          <DataTable
            :value="activeConferences"
            stripedRows
            responsiveLayout="scroll"
            breakpoint="810px"
          >
            <Column field="conferenceName" header="Conference" :sortable="true">
              <template #body="slotProps">
                <div
                  class="speaker-link p-d-flex p-ai-center"
                  @click="
                    router.push({
                      name: 'Conference',
                      params: {
                        id: slotProps.data.uid,
                      },
                    })
                  "
                >
                  <img
                    :src="
                      'https://mdpublishing.com/' +
                        slotProps.data.conferenceName.split(' ').join('') +
                        '.png'
                    "
                    style="width: 40px; height: 40px;
                border-radius: 50%; margin-right: 10px;"
                    alt=""
                  />
                  {{ slotProps.data.conferenceName }}
                </div>
              </template>
            </Column>
            <Column field="city" header="Location" :sortable="true">
              <template #body="slotProps">
                {{ slotProps.data.city }}, {{ slotProps.data.state }}
              </template>
            </Column>
            <Column :sortable="true" header="Year" field="year"> </Column>
            <Column field="quantity" header="Dates" :sortable="true">
              <template #body="slotProps">
                {{ slotProps.data.displayDate.split(", ")[1] }}
                {{ slotProps.data.displayDate.split(", ")[2] }}-{{
                  slotProps.data.displayDate.split(", ")[4]
                }}
                {{ slotProps.data.displayDate.split(", ")[5] }}
              </template>
            </Column>
            <Column field="active" header="Status" :sortable="true">
              <template #body="slotProps">
                <span
                  :class="
                    'conference-status' +
                      ' ' +
                      (slotProps.data.active
                        ? ' active-conference '
                        : ' inactive-conference ')
                  "
                  >{{ slotProps.data.active ? "Active" : "Inactive" }}</span
                >
              </template>
            </Column>
            <Column header="">
              <template #body="slotProps">
                <Button
                  class="p-button-outlined p-button-secondary"
                  :id="slotProps.data.uid"
                  type="button"
                  icon="pi pi-bars"
                  @click="
                    toggle($event);
                    currentUid(slotProps.data.uid);
                  "
                  aria-haspopup="true"
                  aria-controls="overlay_menu"
                />
                <Menu
                  :id="slotProps.data.uid + ' overlay_menu'"
                  ref="menu"
                  :data-cfpUid="slotProps.data.uid"
                  :model="items"
                  :popup="true"
                />
              </template>
            </Column>
          </DataTable>
          <div class="p-text-center  p-p-5">
            <Button
              v-if="!secondSearchPreformed"
              label="Load All Conferences"
              class="p-button-secondary"
              @click="loadInactiveConferences"
            />
            <div v-if="loadingInactive" style="text-align: center;">
              <ProgressSpinner />
            </div>
          </div>
        </div>
        <EditConferenceModal />
        <EditConferenceModal
          :showModal="displayModalnext"
          :conference="conferenceToEdit"
          @modalVisibility="useHideAddNewConference"
          @updateConference="useUpdateConference"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import ICE from "../assets/ICE.png";
import MDExpo from "../assets/ICE.png";
import getUser from "../composables/getUser";
import backgroundImage from "../assets/dark-bg.jpg";

import GetActiveConferences from "../composables/GetActiveConferences";
import GetInactiveConferences from "../composables/GetInactiveConferences";
import EditConferenceModal from "../components/EditConferenceModal.vue";
import { ref, onMounted } from "vue";
export default {
  name: "Conferences",
  components: { EditConferenceModal },

  setup() {
    const router = useRouter();
    const { isAdmin } = getUser();

    const checkIsAdmin = () => {
      if (isAdmin.value) {
        console.log(isAdmin);
        console.log("admin shown");
        return;
      } else {
        console.log("no admin");
        router.push({
          name: "Home",
        });
      }
    };
    checkIsAdmin();
    const {
      getConferences,
      loading,
      getDocError,
      activeConferences,
    } = GetActiveConferences();
    const {
      useGetInactiveConferences,
      loadingInactive,
      inactiveConferences,
    } = GetInactiveConferences();
    const secondSearchPreformed = ref(false);
    onMounted(() => {
      if (activeConferences.value.length < 1) {
        console.log(
          "FETCHING DOCS ON MOUNT!!!!!!!!!!",
          activeConferences.value.length,
          activeConferences.value
        );
        getConferences();
      }
    });

    const activeUid = ref("");

    const currentUid = (uidValue) => {
      activeUid.value = uidValue;
    };

    const toggle = (event) => {
      menu.value.toggle(event);
    };
    const menu = ref();
    const items = ref([
      {
        label: "Actions",
        items: [
          {
            label: "View",
            command: () => {
              router.push({
                name: "Conference",
                params: { id: activeUid.value },
              });
            },
          },
          {
            label: "Edit",
            command: () => {
              editConference(activeUid.value);
              console.log(activeUid.value);
            },
          },
          {
            label: "Delete",
            command: (event) => {
              console.log(event.originalEvent.path[3].dataset.cfpuid);
            },
          },
        ],
      },
    ]);
    const viewConference = (event) => {
      router.push({
        name: "Conference",
        params: {
          name: event.target.dataset.name,
          city: event.target.dataset.city,
          id: event.target.dataset.uid,
          date: event.target.dataset.date,
        },
      });
    };
    const getConfs = async () => {
      await getConferences();
      console.log(loading.value, getDocError.value, activeConferences.value);
    };
    const conferenceToEdit = ref(null);
    const editConference = (confId) => {
      conferenceToEdit.value = activeConferences.value.find(
        (element) => element.uid == confId
      );
      displayModalnext.value += 1;
    };
    const displayAddNewConference = ref(false);

    const useHideAddNewConference = () => {
      console.log("emmit init");
      displayAddNewConference.value = false;
    };
    const displayModalnext = ref(1);
    const loadInactiveConferences = async () => {
      await useGetInactiveConferences();
      console.log(inactiveConferences.value);
      secondSearchPreformed.value = true;
      inactiveConferences.value.forEach((item) => {
        activeConferences.value.push(item);
      });
      // activeConferences.value.push(inactiveConferences.value);
      console.log("👋", activeConferences.value);
    };
    const useUpdateConference = (confData) => {
      console.log("useUpdateConference", confData);
      console.log("activeConferences.value", activeConferences.value);

      activeConferences.value.find((element, index) => {
        if (element.uid == confData.status.uid) {
          activeConferences.value[index] = confData.status;
          return true;
        }
      });
    };
    return {
      useUpdateConference,
      ICE,
      MDExpo,
      router,
      loadingInactive,
      secondSearchPreformed,
      loading,
      viewConference,
      getConfs,
      activeConferences,
      editConference,
      useHideAddNewConference,
      displayModalnext,
      conferenceToEdit,
      loadInactiveConferences,
      activeUid,
      currentUid,
      toggle,
      menu,
      backgroundImage,
      items,
      isAdmin,
    };
  },
};
</script>

<style>
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
}
.conference-status {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  text-align: center;
}
.conference-status.inactive-conference {
  background: #feedaf;
  color: #8a5340;
}
.conference-status.active-conference {
  background: #affeb2;
  color: #408a52;
}
</style>
