import { ref } from "vue";
import { db } from "../firebase/config";

const GetCfps = () => {
  const loadingCfps = ref(false);
  const getDocError = ref(false);
  const matchedCfps = ref([]);
  const useGetCfps = async (field, query) => {
    loadingCfps.value = true
    await db
      .collection("cfps")
      .where(field, "==", query)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          matchedCfps.value.push({ uid: doc.id, ...doc.data() });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        loadingCfps.value = false

      });
      loadingCfps.value = false

  };
  return { useGetCfps, loadingCfps, getDocError, matchedCfps };
};

export default GetCfps;
