<template>
  <Dialog
    header="Edit Conference"
    :modal="true"
    v-model:visible="displayBasic"
    :style="{ width: '50vw' }"
  >
    <label class="p-d-block p-my-2" for="conference">Conference Name</label>
    <Dropdown
      class="p-inputtext-lg"
      v-model="selectedConference"
      :options="conferences"
      style="width: 100%;"
      optionLabel="name"
      optionValue="code"
      placeholder="Select a Conference"
    />
    <label class="p-d-block p-my-2" for="range">Date</label>
    <Calendar
      id="range"
      class="p-inputtext-lg"
      style="width: 100%;"
      v-model="dateRange"
      placeholder="Select Days"
      selectionMode="range"
      dateFormat="DD, MM, dd, yy"
      :manualInput="false"
    />
    <label class="p-d-block p-my-2" for="hotel">Hotel</label>

    <InputText
      type="text"
      v-model="hotelName"
      name="hotel"
      class="p-inputtext-lg"
      placeholder="Hotel Name"
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2" for="city">City</label>

    <InputText
      type="text"
      v-model="cityName"
      name="city"
      class="p-inputtext-lg"
      placeholder="City Name"
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2" for="state">State</label>

    <InputText
      type="text"
      name="state"
      v-model="stateName"
      class="p-inputtext-lg"
      placeholder="State Name"
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2">Active</label>

    <InputSwitch v-model="confActive" />

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        @click="closeBasic"
        class="p-button-text"
      />
      <Button
        label="Update"
        icon="pi pi-check"
        @click="useAddConference"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { ref, watch } from "vue";
import SaveDocument from "../composables/SaveDocument";
import EditDocument from "../composables/EditDocument";

export default {
  name: "EditConferenceModal",
  props: ["showModal", "conference"],
  emits: ["modalVisibility", "updateConference"],
  setup(props, { emit }) {
    const { newDoc, addDocError } = SaveDocument();
    const { useEditDocument, editDocError } = EditDocument();

    const useAddConference = async () => {
      const displayDate = document.querySelector("#range");
      console.log(displayDate.value);
      let updatedDoc = {
        hotel: `${hotelName.value}`,
        city: `${cityName.value}`,
        state: `${stateName.value}`,
        date: `${dateRange.value}`,
        year: displayDate.value.split(" ")[3],

        displayDate: displayDate.value,
        conferenceName: `${selectedConference.value}`,
        active: confActive.value,
      };
      await useEditDocument("conference", updatedDoc, props.conference.uid);
      if (!addDocError.value) {
        emit("updateConference", {
          status: { ...updatedDoc, uid: props.conference.uid },
        });

        console.log("it works", newDoc.value);
        displayBasic.value = false;
      }
    };
    const confActive = ref(true);
    const hotelName = ref("");
    const cityName = ref("");
    const stateName = ref("");
    const displayDate = ref("");
    const test = () => {
      console.log(props.conference.uid);
    };
    const displayBasic = ref(false);

    const openBasic = () => {
      displayBasic.value = true;
    };
    const closeBasic = () => {
      console.log("start emit...");
      emit("modalVisibility", { status: false });

      displayBasic.value = false;
    };

    watch(
      () => props.showModal,
      () => {
        displayBasic.value = true;
      }
    );
    watch(
      () => props.conference,
      (first) => {
        hotelName.value = first.hotel;
        cityName.value = first.city;
        stateName.value = first.state;
        dateRange.value = first.displayDate;
        displayDate.value = first.displayDate;
        selectedConference.value = first.conferenceName;
        confActive.value = first.active;
      }
    );

    const dateRange = ref(null);
    const conferences = [
      { name: "MD Expo", code: "MD Expo" },
      { name: "ICE", code: "ICE" },
      { name: "OR Today Live", code: "OR Today Live" },
      { name: "Periop ConneCT", code: "Periop ConneCT" },
      { name: "HTM Mixer", code: "HTM Mixer" },
      { name: "Georgia Council of periOperative Registered Nurses", code: "Georgia Council of periOperative Registered Nurses" },
    ];

    const selectedConference = ref(null);
    return {
      confActive,
      hotelName,
      cityName,
      stateName,
      addDocError,
      displayBasic,
      openBasic,
      closeBasic,
      dateRange,
      conferences,
      selectedConference,
      test,
      useAddConference,
      editDocError,
    };
  },
};
</script>

<style></style>
