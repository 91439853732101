<template>
  <div
    :style="
      'background-color: #34464B; background-image: url(' +
        backgroundImage +
        '); background-size: cover;'
    "
  >
    <div
      style="max-width: 1400px; margin: auto;     padding-top: 20px;
    padding-bottom: 20px;"
    >
      <h1 class="p-m-3" style="color: white;">
        Presentation Overview
      </h1>
    </div>
  </div>
  <div style="max-width: 1400px; margin: auto;">
    <div v-if="cfp" class="">
      <h1 class="p-m-3">
        {{ cfp.title }}
      </h1>
      <div class="p-d-flex p-ai-center">
        <span :class="'cfp-status ' + ` ${cfp.status}-cfp`" class="p-ml-3">{{
          cfp.status
        }}</span>
        <div
          @click="editCfp"
          class="cfp-status p-m-1 "
          style="background-color: #e1e5e6;"
        >
          EDIT
        </div>
      </div>
    </div>
    <div>
      <div class="p-grid">
        <div class="p-col-12 p-md-7">
          <div v-if="cfp" class="card p-m-3 p-p-3 p-shadow-2 brw">
            <Panel
              class="p-mb-4"
              header="Objectives"
              style="white-space:pre-wrap;
"
            >
              {{ cfp.objective }}
            </Panel>
            <Panel
              header="Description"
              style="white-space:pre-wrap;"
              class="p-mb-4"
              >{{ cfp.description }}</Panel
            >
            <Panel
              header="Co-Presenter"
              style="white-space:pre-wrap;"
              class="p-mb-4"
              >{{ cfp.copresenter }}</Panel
            >
            <Panel
              header="Target Audience"
              style="white-space:pre-wrap;"
              class="p-mb-4"
              >{{ cfp.audience }}</Panel
            >
            <Panel
              header="Travel Expenses"
              style="white-space:pre-wrap;"
              class="p-mb-4"
              >{{ cfp.reimbursement }}</Panel
            >
            <Panel
              header="Permission to Record"
              style="white-space:pre-wrap;"
              class="p-mb-4"
              >{{ cfp.record }}</Panel
            >
          </div>
        </div>
        <div class="p-col-12 p-md-5">
          <div v-if="cfp" class="card p-m-3 p-p-3 p-shadow-2 brw">
            <Panel
              header="Presenter"
              style="white-space:pre-wrap;"
              class="p-mb-4"
              ><div class="p-d-flex p-ai-center p-jc-between">
                <div>
                  <h3>{{ cfp.firstName }} {{ cfp.lastName }}</h3>
                  <p>{{ cfp.jobTitle }}, {{ cfp.company }}</p>
                </div>
                <div class="p-mr-2">
                  <Avatar
                    :image="cfp.imageCropUrl"
                    size="xlarge"
                    shape="circle"
                  />
                </div></div
            ></Panel>
            <!-- <button
            @click="
              router.push({
                name: 'Speaker',
                params: {
                  id: cfp.userUid,
                },
              })
            "
          >
            home
          </button> -->
            <Panel
              header="Contact"
              style="white-space:pre-wrap;"
              class="p-mb-4"
            >
              <a
                v-if="cfp.workPhone"
                :href="'tel:' + cfp.cell"
                class="p-d-flex p-ai-center p-my-3"
              >
                <i class="pi pi-phone"></i> work: {{ cfp.workPhone }}
              </a>
              <a :href="'tel:' + cfp.cell" class="p-d-flex p-ai-center p-my-3">
                <i class="pi pi-phone"></i> cell: {{ cfp.cell }}
              </a>
              <a
                :href="'mailto:' + cfp.email"
                class="p-d-flex p-ai-center p-my-3"
              >
                <i class="pi pi-envelope"></i> {{ cfp.email }}
              </a>
              <a
                :href="
                  'https://www.google.com/maps/search/' +
                    cfp.address +
                    ' ' +
                    cfp.city +
                    ' ' +
                    cfp.state +
                    '/'
                "
                target="_blank"
                class="p-d-flex p-ai-center p-my-3"
              >
                <i class="pi pi-map-marker"></i> {{ cfp.address }},
                {{ cfp.city }}, {{ cfp.state }} {{ cfp.zip }}
              </a>
            </Panel>
            <Panel
              header="Actions"
              style="white-space:pre-wrap;"
              class="p-mb-4"
            >
              <div class="p-fluid">
                <Button
                  class="p-my-1"
                  v-if="isAdmin"
                  label="Send Email"
                  icon="pi pi-send"
                  iconPos="left"
                  @click="useSendMessage"
                />
                <Button
                  class="p-my-1"
                  v-if="cfp.resumeUrl"
                  label="Download Resume"
                  icon="pi pi-file"
                  iconPos="left"
                  @click="downloadFile(cfp.resumeUrl)"
                />
                <Button
                  class="p-my-1"
                  v-if="cfp.imageUrl"
                  label="Download Headshot"
                  icon="pi pi-camera"
                  iconPos="left"
                  @click="downloadFile(cfp.imageUrl)"
                />
                <Button
                  class="p-my-1"
                  label="View All Submissions"
                  icon="pi pi-eye"
                  iconPos="left"
                  @click="
                    router.push({
                      name: 'Speaker',
                      params: {
                        id: cfp.userUid,
                      },
                    })
                  "
                />
              </div>
            </Panel>
            <Panel
              header="Experience"
              style="white-space:pre-wrap;"
              class="p-mb-4"
              v-if="cfp.certifications || cfp.experience || cfp.education"
            >
              <p v-if="cfp.certifications">{{ cfp.certifications }}</p>
              <p v-if="cfp.experience">{{ cfp.experience }}</p>
              <p v-if="cfp.education">{{ cfp.education }}</p>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditCfpModal
    :showModal="displayEditModalnext"
    :user="presentationToEdit"
    :presentation="presentationToEdit"
    @modalVisibility="useHideAddNewConference"
    @pushCfpEditSpeaker="usePushCfpEdit"
  />
  <SendMessage
    :showModal="displaySendMessageModalnext"
    :speaker="cfp"
    @modalVisibility="useHideSendMessage"
    @pushCfp="usePushEmail"
  />
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import GetCfp from "../composables/GetCfp";
import { ref } from "vue";
import EditCfpModal from "../components/EditCfpModal.vue";
import backgroundImage from "../assets/dark-bg.jpg";
import getUser from "../composables/getUser";
import SendMessage from "../components/SendMessage.vue";

export default {
  name: "ViewCfp",
  components: { EditCfpModal, SendMessage },

  setup(params) {
    console.log(params);
    const { user, isAdmin } = getUser();
    const displaySendMessageModalnext = ref(1);
    const useSendMessage = () => {
      console.log("starting message modal display");
      displaySendMessageModalnext.value += 1;
    };
    const route = useRoute();
    const router = useRouter();
    const cfpUid = ref(route.params.id);
    const cfpref = ref(cfp);
    console.log(route.params.id);
    const { useGetCfp, loadingGetSpeaker, getCfpError, cfp } = GetCfp();
    useGetCfp(route.params.id);
    const downloadFile = (url) => {
      window.location.href = url;
    };

    const displayEditModalnext = ref(1);
    const presentationToEdit = ref(null);
    const editCfp = () => {
      console.log("start edit");
      presentationToEdit.value = cfp.value;
      console.log(cfp.value);
      displayEditModalnext.value += 1;
    };

    const usePushCfpEdit = (submission) => {
      console.log("🦄");
      console.log("from parent", submission);
      cfp.value = submission;
    };
    return {
      displaySendMessageModalnext,
      useSendMessage,
      user,
      isAdmin,
      SendMessage,
      presentationToEdit,
      displayEditModalnext,
      editCfp,
      usePushCfpEdit,
      cfp,
      loadingGetSpeaker,
      getCfpError,
      cfpUid,
      router,
      cfpref,
      backgroundImage,
      downloadFile,
    };
  },
};
</script>

<style>
.cfp-status {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  text-align: center;
}
.cfp-status.pending-cfp {
  background: #feedaf;
  color: #8a5340;
}
.cfp-status.declined-cfp {
  background: #feafaf;
  color: #8a4040;
}
.cfp-status.accepted-cfp {
  background: #affeb2;
  color: #408a52;
}
/* unvisited link */
a:link {
  color: #0e8aec;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #0e8aec;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: #0e8aec;
  text-decoration: underline;
}

/* selected link */
a:active {
  color: #0e8aec;
  text-decoration: underline;
}
</style>
