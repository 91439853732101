import { projectStorage } from "../firebase/config";
import { ref } from "vue";
import getUser from "./getUser";

const { user } = getUser();

const useStorage = () => {
  const error = ref(null);
  const url = ref(null);
  const filePath = ref(null);

  const uploadResume = async (file, name) => {
    filePath.value = `speakers/${user.value.uid}/${name}`;
    const storageRef = projectStorage.ref(filePath.value);

    try {
      const res = await storageRef.put(file);
      url.value = await res.ref.getDownloadURL();
    } catch (err) {
      console.log(err.message);
      error.value = err;
    }
  };

  const uploadImage = async (file, name) => {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();

    filePath.value = `speakers/${user.value.uid}/${year}/${month}/${day}/${name}`;
    const storageRef = projectStorage.ref(filePath.value);

    try {
      const res = await storageRef.putString(file, "data_url");
      url.value = await res.ref.getDownloadURL();
    } catch (err) {
      console.log(err.message);
      error.value = err;
    }
  };
  const uploadImageStandard = async (file, name) => {
    filePath.value = `speakers/${user.value.uid}/${name}`;
    const storageRef = projectStorage.ref(filePath.value);

    try {
      const res = await storageRef.put(file);
      url.value = await res.ref.getDownloadURL();
    } catch (err) {
      console.log(err.message);
      error.value = err;
    }
  };

  const deleteImage = async (path) => {
    const storageRef = projectStorage.ref(path);

    try {
      await storageRef.delete();
    } catch (err) {
      console.log(err.message);
      error.value = err;
    }
  };

  return {
    uploadResume,
    uploadImage,
    uploadImageStandard,
    deleteImage,
    url,
    filePath,
    error,
  };
};

export default useStorage;
