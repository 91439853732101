import { ref } from "vue";
import { db } from "../firebase/config";

const SaveDocument = () => {
  const newDoc = ref("");
  const addDocError = ref(false);
  const addDocument = async (collection, document) => {
    newDoc.value = ";"
    await db
      .collection(collection)
      .add(document)
      .then((docRef) => {
        newDoc.value = docRef.id;
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };
  return { addDocument, newDoc, addDocError };
};

export default SaveDocument;
