import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import AddConference from "../views/AddConference.vue";
import Conferences from "../views/Conferences.vue";
import Conference from "../views/Conference.vue";
import Speakers from "../views/Speakers.vue";
import Speaker from "../views/Speaker.vue";
import StartProfile from "../views/StartProfile.vue";
import EditProfile from "../views/EditProfile.vue";
import ViewProfile from "../views/ViewProfile.vue";
import ViewCfp from "../views/ViewCfp.vue";
import CropTest from "../views/CropTest.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/start",
    name: "StartProfile",
    component: StartProfile,
  },
  {
    path: "/add-conference",
    name: "AddConference",
    component: AddConference,
  },
  {
    path: "/conferences",
    name: "Conferences",
    component: Conferences,
  },
  {
    path: "/conference/:id",
    name: "Conference",
    component: Conference,
    props: true,
  },
  {
    path: "/editprofile/:id",
    name: "EditProfile",
    component: EditProfile,
    props: true,
  },
  {
    path: "/viewprofile/:id",
    name: "ViewProfile",
    component: ViewProfile,
    props: true,
  },
  {
    path: "/presentation/:id",
    name: "ViewCfp",
    component: ViewCfp,
    props: true,
  },
  {
    path: "/speakers",
    name: "Speakers",
    component: Speakers,
  },
  {
    path: "/crop",
    name: "CropTest",
    component: CropTest,
  },
  {
    path: "/speaker/:id",
    name: "Speaker",
    component: Speaker,
    props: true,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
