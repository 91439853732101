<template>
  <div class="flex-wrapper">
    <div class="container">
      <div v-if="user">
        <AdminNav v-if="isAdmin" />
        <Navbar v-else-if="user" />
      </div>
      <router-view />
    </div>
    <div class="footer p-text-center p-mt-4  p-p-3">
      <div
        class="p-text-center p-mt-5"
        style="margin: auto; text-align: center; color: #96a7b3;"
      >
        <div
          style="max-width: 400px;  margin-left: auto!important;  margin-right: auto!important;"
        >
          <img
            class="p-mb-5"
            src="./assets/conferences.png"
            alt="conference logos"
          />
        </div>
      </div>
      <p style="color:#96a7b3!important;">© MD Publishing 2021</p>
    </div>
  </div>
</template>
<script>
// import { functions } from "./firebase/config";
// import { useRouter } from "vue-router";
import Navbar from "./components/Navbar.vue";
import AdminNav from "./components/AdminNav.vue";
import getUser from "./composables/getUser";
import { ref } from "vue";
export default {
  components: { Navbar, AdminNav },
  setup() {
    // const router = useRouter();
    // const addAdminRole = functions.httpsCallable("addAdminRole");
    // addAdminRole({ email: "web@mdpublishing.com" }).then((result) => {
    //   console.log(result);
    //   console.log("!@#$!@#$!@#$");
    // });

    // addAdminRole();

    const showANav = ref(false);
    const { user, isAdmin } = getUser();
    console.log(user.value, "user");

    // const checkAdmin = () => {
    //   console.log("isAdmin.value", isAdmin.value);
    //   if (isAdmin.value) {
    //     router.push({
    //       name: "Conferences",
    //     });
    //     return;
    //   } else if (user.value) {
    //     router.push({
    //       name: "Speaker",
    //       params: {
    //         id: user.value.uid,
    //       },
    //     });
    //   } else {
    //     return;
    //   }
    // };

    // checkAdmin();

    return { showANav, user, isAdmin };
  },
};
</script>

<style>
body {
  margin: 0px;
  background-color: #fcfcfc;
  color: #495057;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}
h1 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: rgb(73, 80, 87);
}
h2 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: rgb(73, 80, 87);
}
h5 {
  font-size: 1.25rem;
}

.brw {
  background-color: white;
  border-radius: 10px;
}
.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}
</style>
