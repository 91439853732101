import { ref } from "vue";
import { db } from "../firebase/config";

const GetAllSpeakers = () => {
  const loading = ref(false);
  const getDocError = ref(false);
  const speakers = ref([]);
  const getConferences = async () => {
    await db
      .collection("speakers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          speakers.value.push({ uid: doc.id, ...doc.data() });
        });
      });
  };
  return { getConferences, loading, getDocError, speakers };
};

export default GetAllSpeakers;
